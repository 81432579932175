import React from "react";
import { SEO, PageLayout, HeroElement, Content } from "@bluefin/components";
import { Grid, Header, List } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class ShippingPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Shipping"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <Content container={true}>
                <Content.Markup isMarkdown={false}>
                  <Header as={"h1"} content={"HOLIDAY SHIPPING GUIDE"} />
                  <p>
                    We ship FedEx priority overnight Mon-Fri for next day
                    delivery. There is an extra $20 for Saturday delivery.
                    Overnight delivery is guaranteed with the exceptions being
                    during the holidays or severe weather.
                  </p>
                  <p>Please call for shipping prices</p>
                  <p>
                    <a href={"tel:+12073486768"} target={"_blank"}>
                      (207) 348-6768
                    </a>
                  </p>
                  <p>
                    Our specialty is LIVE MAINE LOBSTER. From the icy offshore
                    waters of coastal Stonington Maine to your door, directly
                    off our boats.
                  </p>
                  <p>
                    Other items we ship may be determined by the season and
                    availability:
                  </p>
                  <List
                    items={[
                      "Local Maine scallops (1 gallon min)",
                      "Haddock (5lb min)",
                      "Whole loin Tuna (4-7lb min)",
                      "Salmon (whole filet 3lb avg)",
                      "Frozen pint chowders Clam, Seafood, or Lobster bisque, frozen shucked oysters (1lb pkg)",
                      "Rope grown mussels (10lb min)",
                      "Steamers (10lb min)",
                      "Lobster dip (8oz pkg)",
                      "T-shirts, Hats, Gift Cards",
                    ]}
                  />
                </Content.Markup>
              </Content>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Shipping" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
